import { GraphQLClient } from 'graphql-request'
import environment from '../../utils/environment'
import { Reports } from './api/reports'
import { ApolloClient, InMemoryCache } from '@apollo/client'

const endpoint = environment.QSERVICES_REPORTING_API_URL

export const client = new ApolloClient({
  uri: endpoint,
  cache: new InMemoryCache(),
})

// const client = new GraphQLClient(endpoint, { errorPolicy: 'all' })
//
export const QServiceReports = {
  Reports: Reports(client),
}
