import {
  AMBASSADOR_VOLUME_TRACKING_REPORT,
  EXECUTIVE_TIMECLOCK_REPORT,
  LEG_VOLUMES_REPORT,
  NEW_ENROLLMENTS_REPORT,
  PERSONAL_CUSTOMERS_REPORT,
  RANK_ADVANCEMENT_REPORT,
} from '../graphql/reports'

export const Reports = client => ({
  getAmbassadorVolumeTrackingReport: async data =>
    await client
      .query({
        query: AMBASSADOR_VOLUME_TRACKING_REPORT,
        variables: data,
      })
      .then(response => {
        return response.data.associatesTreeReport
      }),
  getExecutiveTimeClockReport: async data =>
    await client
      .query({
        query: EXECUTIVE_TIMECLOCK_REPORT,
        variables: data,
      })
      .then(response => {
        return response.data.associatesTreeReport
      }),
  getLegVolumesReport: async data =>
    await client
      .query({
        query: LEG_VOLUMES_REPORT,
        variables: data,
      })
      .then(response => {
        return response.data.associatesTreeReport
      }),
  getNewEnrollmentsReport: async data =>
    await client
      .query({
        query: NEW_ENROLLMENTS_REPORT,
        variables: data,
      })
      .then(response => {
        return response.data.associatesTreeReport
      }),
  getPersonalCustomersReport: async data =>
    await client
      .query({
        query: PERSONAL_CUSTOMERS_REPORT,
        variables: data,
      })
      .then(response => {
        return response.data.associatesTreeReport
      }),
  getRankAdvancementReport: async data =>
    await client
      .query({
        query: RANK_ADVANCEMENT_REPORT,
        variables: data,
      })
      .then(response => {
        return response.data.associatesTreeReport
      }),
})
