import { GridColDef } from '@mui/x-data-grid-pro'

export const getColumns = prismic => {
  const columns: GridColDef[] = [
    {
      field: 'rowId',
      headerName: 'Row Id',
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'legacyAssociateId',
      headerName: prismic.id,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'name',
      headerName: prismic.name,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'associateType',
      headerName: prismic.customer_type,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'associateStatus',
      headerName: prismic.status,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'currentOvRankName',
      headerName: prismic.current_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'highestOvRankName',
      headerName: prismic.highest_ov_rank,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'phone',
      headerName: prismic.phone,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'email',
      headerName: prismic.email,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'signupDate',
      headerName: prismic.start_date,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'enrollerName',
      headerName: prismic.sponsor,
      minWidth: 115,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'autoShipStatus',
      headerName: prismic.subscription_status,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'pv',
      headerName: prismic.personal_volume,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'commissionsActive',
      headerName: prismic.commissions_active,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'pa',
      headerName: prismic.personally_ssponsored_active,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'leg1Volume',
      headerName: prismic.leg_1_volume,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'leg2Volume',
      headerName: prismic.leg_2_volume,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'leg3Volume',
      headerName: prismic.leg_3_volume,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'totalOv',
      headerName: prismic.total_ov,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
    {
      field: 'cv',
      headerName: prismic.customer_volume,
      minWidth: 200,
      headerClassName: 'grid-header',
      align: 'left',
      headerAlign: 'left',
      cellClassName: 'cell',
    },
  ]
  return columns
}
