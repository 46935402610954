import React, { useState, useEffect } from 'react'
import { PageHeader, Seo } from '../../components'
import DataGrid from '../../components/DataGrid'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { QServiceReports } from '../../services/q-rervices-reporting'
import { LicenseInfo } from '@mui/x-license-pro'
import { getColumns } from '../../components/DataGrid/reportingConfig/amb-volume-tracking'

LicenseInfo.setLicenseKey(
  '1f972e92138c97fff68b29aaaa534075Tz02NDkxNyxFPTE3MTM2MzQ1NDUzNTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)
// TODO - copy en US prismic to other locales
const AmbVolumeTracking = () => {
  const { qUser } = useAuthContext()
  const {
    prismicData: { prismicReports },
  } = usePrismic()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState('')

  const dateEnd = new Date()
  const dateStart = new Date(2019, dateEnd.getMonth(), 1) // Current month only
  const input = {
    legacyAssociateId: qUser?.legacyAssociateId,
    dateStart: dateStart,
    dateEnd: dateEnd,
    associateTypes: ['AMBASSADOR'],
    associateStatuses: [],
    filterTypes: ['SIGNUP_DATE'],
    countryCodes: [],
  }

  const columns = getColumns(prismicReports)

  const getAmbassadorVolumeTrackingReport = async () => {
    setLoading(true)
    await QServiceReports.Reports.getAmbassadorVolumeTrackingReport({ input })
      .then(response => setData(response))
      .catch(err => setError(err))
    setLoading(false)
  }

  useEffect(() => {
    if (qUser) {
      getAmbassadorVolumeTrackingReport()
    }
  }, [qUser])

  return (
    <>
      <Seo title={prismicReports.amb_volume_tracking} />
      <PageHeader exitRoute="/reports">
        {prismicReports.amb_volume_tracking}
      </PageHeader>
      <DataGrid columns={columns} data={data} loading={loading} error={error} />
    </>
  )
}

export default AmbVolumeTracking
