import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import {
  DataGridPro,
  GridToolbar,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro'
import { LicenseInfo } from '@mui/x-license-pro'
import { Container } from '../common'
import { ErrorLabel } from '../Enrollment/EnrollmentForm.styles'
import { usePrismic } from '../../context/PrismicContext'

LicenseInfo.setLicenseKey(
  '1f972e92138c97fff68b29aaaa534075Tz02NDkxNyxFPTE3MTM2MzQ1NDUzNTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const LoadingSpinner = styled(Icon)`
  color: ${props => props.theme.colors.turquoiseGreen};
`

const LoadingText = styled.span`
  color: ${props => props.theme.colors.dimGray};
  margin-top: 1rem;
`

const DataGrid = ({ data, columns, loading, error, ...props }) => {
  const {
    prismicData: { prismicReports },
  } = usePrismic()

  const [counter, setCounter] = useState(0)
  const [intervalID, setIntervalID] = useState(-1)
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      rowId: false,
    })
  const startInterval = () => {
    setIntervalID(
      setInterval(() => {
        setCounter(prev => prev + 1)
      }, 1000)
    )
  }

  const stopInterval = () => {
    clearInterval(intervalID)
    setIntervalID(-1)
    setCounter(0)
  }

  useEffect(() => {
    if (loading) {
      startInterval()
    } else {
      stopInterval()
    }
    return () => {
      stopInterval()
      clearTimeout(intervalID)
    }
  }, [loading])

  const minutes = Math.floor(counter / 60)
  const seconds = counter - minutes * 60
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

  if (loading) {
    return (
      <Container direction="column">
        <LoadingSpinner loading size="large" name="circle notched" />
        <LoadingText>{prismicReports.loading_report}</LoadingText>
        <LoadingText>{`${minutes}:${formattedSeconds}`}</LoadingText>
      </Container>
    )
  }
  if (error) {
    return (
      <Container>
        <ErrorLabel>{error.message}</ErrorLabel>
      </Container>
    )
  }
  if (data?.length === 0) {
    return (
      <Container>
        <LoadingText>{prismicReports.no_data_available}</LoadingText>
      </Container>
    )
  }
  if (data === null) {
    return (
      <Container>
        <LoadingText>{prismicReports.report_will_start}</LoadingText>
      </Container>
    )
  }
  return (
    <DataGridPro
      getRowId={row => row.rowId}
      columns={columns}
      rows={data}
      density="compact"
      loading={loading}
      slots={{ toolbar: GridToolbar }}
      pageSizeOptions={[25, 50, 100]}
      pagination
      paginationMode="client"
      initialState={{
        pagination: {
          paginationModel: { pageSize: 25, page: 0 },
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel =>
        setColumnVisibilityModel(newModel)
      }
      {...props}
    />
  )
}

export default DataGrid
