import { gql } from '@apollo/client'

export const AMBASSADOR_VOLUME_TRACKING_REPORT = gql`
  query GetAmbassadorVolumeTrackingReport($input: AssociatesTreeReportInput!) {
    associatesTreeReport(input: $input) {
      rowId
      name
      legacyAssociateId
      associateType
      associateStatus
      currentOvRankName
      highestOvRankName
      phone
      email
      signupDate
      enrollerName
      autoShipStatus
      pv
      commissionsActive
      pa
      leg1Volume
      leg2Volume
      leg3Volume
      totalOv
      cv
    }
  }
`

export const EXECUTIVE_TIMECLOCK_REPORT = gql`
  query GetExecutiveTimeClockReport($input: AssociatesTreeReportInput!) {
    associatesTreeReport(input: $input) {
      rowId
      associateId
      legacyAssociateId
      name
      associateType
      associateStatus
      currentOvRankName
      highestOvRankName
      phone
      email
      signupDate
      enrollerName
      enrollerLegacyAssociateId
      autoShipStatus
      pv
      commissionsActive
      pa
      leg1Volume
      leg2Volume
      leg3Volume
      totalOv
    }
  }
`

export const LEG_VOLUMES_REPORT = gql`
  query GetLegVolumesReport($input: AssociatesTreeReportInput!) {
    associatesTreeReport(input: $input) {
      rowId
      name
      legacyAssociateId
      associateType
      associateStatus
      currentOvRankName
      highestOvRankName
      phone
      email
      ov
      treeLevel
    }
  }
`

export const NEW_ENROLLMENTS_REPORT = gql`
  query GetNewEnrollmentsReport($input: AssociatesTreeReportInput!) {
    associatesTreeReport(input: $input) {
      rowId
      name
      treeLevel
      legacyAssociateId
      associateType
      associateStatus
      currentOvRankName
      highestOvRankName
      email
      signupDate
      enrollerName
      pv
    }
  }
`

export const PERSONAL_CUSTOMERS_REPORT = gql`
  query GetPersonalCustomersReport($input: AssociatesTreeReportInput!) {
    associatesTreeReport(input: $input) {
      rowId
      name
      legacyAssociateId
      associateType
      associateStatus
      phone
      email
      autoShipStatus
    }
  }
`

export const RANK_ADVANCEMENT_REPORT = gql`
  query GetRankAdvancementReport($input: AssociatesTreeReportInput!) {
    associatesTreeReport(input: $input) {
      rowId
      legacyAssociateId
      firstName
      lastName
      associateStatus
      phone
      email
      countryName
      countryCode
      signupDate
      enrollerName
      enrollerLegacyAssociateId
      priorOvRankName
      currentOvRankName
      highestOvRankName
      priorCvRankName
      currentCvRankName
      highestCvRankName
      treeLevel
    }
  }
`
